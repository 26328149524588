@import '../../../../../@theme/variables.scss';

.suggestions {
  position: absolute;
  border-radius: 0px 0px 8px 8px;
  border-color: #F7F8FB;
  z-index: 1;
  background-color: #FFF;
  margin: 3px auto;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding-left: 0;
  list-style-type: disc;
  box-shadow: 3px 7px 5px rgba(0, 0, 0, 0.1);
  li {
    padding: 0.75rem;
    text-align: left;
    &:not(:last-of-type) {
      border-bottom: 1px solid #E7E7E7;
    }
    &:hover {
      color: #21d4fd;
      cursor: pointer;
      font-weight: 700;
    }
  }
}
