@import '../../../../../@theme/variables.scss';

.employeeInfoActions {
  .flexEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .dropdown-menu {
    margin: 0px;
    padding: 0px;
    min-width: 5rem;
  }

  i {
    font-size: 0.8rem;
    margin: 0px 10px;
  }

  .button {
    text-decoration: none;
  }

  .dropdown-item {
    color: $red-2;
    font-size: 0.8rem;
    padding: 0.5rem 0.5rem;
    &:hover,
    &:active {
      color: $red;
      background-color: $gray-3;
    }
  }
}
