@import '../../../../../../@theme/variables';

.area {
  display: flex;
  flex-direction: column;
  &-interviewTypes {
    display: flex;
    flex-direction: row;
    &__option {
      font-size:$font-size-200;
      padding: 0.313rem 1.25rem;
      margin: 0.313rem;
      letter-spacing: 0;
      line-height: 1.5rem;
      color: $blue;
    }
    &-subCategory {
      display: flex;
      flex-direction: row;
      &__option {
        display: flex;
        border: 1px solid $blue-1;
        border-radius: 4px;
        font-size: 0.875rem;
        padding: 0.313rem 0.938rem;
        margin: 0.313rem;
        letter-spacing: 0;
        line-height: 0.938rem;
        align-items: center;
      }
    }
  }
  &-interviewTypes:first-child {
    .area-interviewTypes__option {
      color: $gold-2;
    }
    .area-interviewTypes-subCategory__option {
      border: 1px solid $gold-3;
    }
  }
}

.leads {
  border-radius: 50%;
  border: 0.5px solid $gold;
  margin-left: 0.313rem;
  margin-right: 0.625rem;
  height: 3.125rem;
  width: 3.125rem;
}

