@import '../../../../@theme/variables';
.link-disabled {
  text-decoration: none;
  pointer-events: none;
  cursor: not-allowed;
  i {
    color: $gray-6;
  }
}
.disable-icon {
  height: 18px;
  width: 18px;
}
