@import "../../@theme/variables";

video {
  position: fixed;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.home-video {
  background-color: $black;
  .content-login {
    align-items: initial;
    background: rgba(0, 0, 0, 0.80);
    box-sizing: content-box;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 5rem 0;
    max-width: 554px;
    width: 100%;
    a {
      margin: auto;
    }
  }

  img {
    width: 100%;
    height: 4.125rem;
  }

  .btn{
    width: 18rem;
    height: 3rem;
  }
  
}
