.breadcrumb {
  // font-family: 'AvenirBook', sans-serif;
  font-size: 12px;
  background: transparent;
}
.breadcrumb-item {
  font-weight: bold;
  a {
    font-weight: normal;
    text-decoration: none;
    &:not(:hover) {
      color: inherit;
    }
  }
}
.breadcrumb-item + .breadcrumb-item::before {
  content: '\276F';
  font-size: 10px;
}
