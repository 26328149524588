@import '../../@theme/variables.scss';

.error {
  display: flex;
  flex-direction: column;
  height: 100vh;
  &-header {
    align-items: center;
    background-color: $black;
    color: $white;
    display: flex;
    padding: 10px 0;
  }
  &-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url('../../assets/images/MicrosoftTeams-image.png');
    height: 100%;
    width: 100%;
    &-box {
      align-items: center;
      background-color: $gray-1;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      padding: 64px 101px;
      &__icon {
        height: 92px;
        width: 102px;
      }
      &-texts {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__title {
          color: #000000;
          font-family: Lato;
          font-size: 30px;
          font-weight: bold;
          letter-spacing: -0.84px;
          line-height: 36px;
        }
        &__desc {
          font-size: 26px;
          font-weight: normal;
          letter-spacing: -0.73px;
          line-height: 32px;
        }
        &__bold {
          color: $gold-5;
          font-size: 26px;
          font-weight: bold;
          letter-spacing: -0.73px;
          line-height: 32px;
        }
      }
    }
  }
}
