@import '../../../../@theme/variables.scss';

.interviews table {
  border-collapse: collapse;;
  border-spacing: 0;
  position: relative;
  tbody td:first-of-type{
    padding: 0.938rem;
  }
  .small-width .btn-sort{
    min-width: 110px;
    white-space: nowrap;
  }
  td:last-of-type {
    i{
      color: $red;
      font-size: $font-size-500;
    }
  }
}

.notesPill {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  margin-right: 5px;
  cursor: pointer;

  &:hover{
    background-color: darken($color: $red, $amount: 10);
  }
}

.circle-lights {
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  width: 12px;
  &.green {
    background: url('../../../../assets/icons/finalizado.svg') $green;
  }
  &.red {
    background: url('../../../../assets/icons/debaja.svg') $red;
  }
  &.yellow {
    background: url('../../../../assets/icons/enproceso.svg') $yellow;
  }
  &.gray {
    background: url('../../../../assets/icons/noprogramada.svg') $gray-1;
  }
}

.list-group {
  z-index: 17;
}

.find-interview-btn {
  display: inline-block;
  margin: 11px 10px;
}

.check-internal-interviews {
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 90px;
  margin-bottom: 20px;
}

