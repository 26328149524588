.top-bar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    padding-right: 0;

    &__search {
        width: 30%;
    }
}



