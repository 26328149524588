@import '../../../../../../@theme/variables';

.AddInterview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $gray-1;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 20px 0;
  padding: 15px;
  &-form {
    .form-control {
      background-color: $white !important;
    }

    .form-control:focus,
    .custom-select:focus {
      background-color: $white !important;
    }
  }
}