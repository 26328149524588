@import '~bootstrap/scss/bootstrap.scss';
@import 'material-icons/iconfont/material-icons.scss';
@import 'variables';
@import 'mixins';
@import '~lighthouse-ui-core-stencil/dist/collection/styles/global';

.container-fluid {
  background-color: $gray-1;
  flex: 1;
}

.small-width {
    min-width: 100px;
  i {
    margin-right: 10px;
  }
}

.skills-container{
  position: relative;
  .btns-skill-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 12.375rem;
    .btn{
      margin-right: 1.25rem;
    }
  }
}

.notification-title{
  margin-bottom: 3px;
}


a{
  color: $red;
  &:hover{
    color: $gold;
  }
}