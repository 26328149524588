@import '../../../../../@theme/variables';
/* The container */

.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 40px;
  margin: 30px 20px 20px 0px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  border: 0.05px solid $red;
  box-shadow: 0 0 1px $red;
}

/* When the checkbox is checked, add a white background */
.checkbox-container input:checked ~ .checkmark {
  border: 0.05px solid $red;
  box-shadow: 0 0 1px $red;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 10px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $red;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
