@import '../../../../../../@theme/variables';

.EditArea {
  display: block;
  width: 350px;
  height: 200px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  &-form {
    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }
    &__addInterview {
      max-height: 500px;
      overflow-y: scroll;
      overflow: auto;
    }
  }
}


.modal-body {
  max-height: 580px;
  height: 100%;
  overflow-y: auto;
}