@import '../../../../../@theme/variables.scss';

.btn-go-back, .btn-go-back .material-icons {
  color: $red;
  cursor: pointer;
  font-size: smaller;
}

.column-left {
  border-right: 1px solid $gray-3;
}