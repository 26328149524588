.half-width {
    display: inline-block;
    width: 35%;
    margin-right: 1.875rem;
}

.save-btn {
    display: flex;
    align-self: center;
    margin-bottom: 4px;
}

// .mt-5 {
//     display: inline-block !important;
//     width: 15%;
//     margin-top: 0 !important;
//     margin-left: 6.25rem;
// }

.fields-container {
    display: flex;
    justify-content: space-between;
}