@import '../../../../../../@theme/variables.scss';
@import '~react-big-calendar/lib/css/react-big-calendar.css';

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $red;
  background: $red;
}

.event-is-background {
  opacity: 0.5;
}

.rbc-event:not(.event-is-background) {
  z-index: 1;
}