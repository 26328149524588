@import '../../../../../@theme/variables.scss';

.navigationBar {
  margin-bottom: 20px;

  .main-nav__items {
    display: flex;
    margin: 0;
    padding-bottom: 10px;
    list-style: none;
    border-bottom: 1px solid $gold-2;
  }

  .main-nav__item {
    margin: 0 25px;
  }

  .main-nav__item a {
    text-decoration: none;
    color: $gray-3;
    font-weight: lighter;
    font-size: 0.8rem;
    padding-bottom: 12px;
    &:hover {
      color: $gray-6;
      font-weight: 900;
      border-bottom: 2px solid $gold;
    }
  }

  .main-nav__item_selected {
    color: $gray-6;
    font-weight: 900;
    border-bottom: 2px solid $gold;
  }
}
