.datepicker {
  align-items: center;
  display: flex;
  position: relative;
  justify-content: space-between;
  [type='date'] {
    appearance: none;
  }
  [type='date']::-webkit-inner-spin-button,
  [type='date']::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
  i {
    cursor: pointer;
    position: absolute;
    right: 20px;
  }
}
