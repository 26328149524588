@import '../../../../../@theme/variables';

.no-suggestion {
  padding: 0.5rem;
}

.suggestion {
  position: absolute;
  left: 0;
  top: 40px;
  border-radius: 8px;
  list-style: none;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  border-color: $gold;
  background-color: $gold-1;
  width: calc(242px + 1rem);
  z-index: 1;
}

.suggestion li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestion li:hover {
  background-color: $gray-3;
  color: $black;
  cursor: pointer;
  font-weight: 700;
}

.suggestion li:not(:last-of-type) {
  border-bottom: 1px solid $gray-3;
}
