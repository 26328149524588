@import '../../../../@theme/variables';

.config-areas-custom-select {
  width: 120px;
}

.report {
  .table {
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
  th {
    padding-right: 1em;
    white-space: nowrap;
  }
  td:first-child {
    min-width: 240px;
  }
}

table.schedule-analysis {
  td {
    &:not(.text-nowrap) > .text-cell {
      display: inline-block;
      text-align: right;
      width: 32px;
    }
    .circle-lights {
      border-radius: 50%;
      display: inline-block;
      height: 10px;
      margin-left: 0.5rem;
      width: 10px;
      &.green {
        background-color: $green;
      }
      &.red {
        background-color: $red;
      }
      &.yellow {
        background-color: $yellow;
      }
      &.gray {
        background-color: $gray-1;
      }
    }
  }
}
