@import '../../../../@theme/variables';

.table-edit-area {
  margin: 0.2rem 0.5rem;
}

.td-delete-item {
  cursor: pointer;
  color: red;
}

.input-add-item-area {
  width: 35%;
  margin-left: 0.2rem;
}

.btn-add-item-area {
  margin-left: 1rem;
}

.row-intput-area {
  margin-left: 0.4rem;
}

tbody {
  .input-ham-area:not(:hover) {
    border-color: transparent;
  }
}
